import React from 'react'
import { Link } from 'gatsby'
import { createSlug } from '../../utilities/helpers'


const ChapterNavLink = ({ link_number, chapters, parent_slug, link_text }) => {

  if (link_number >= 0) {
    let linkSlug;
    let linkTitle;

    if (chapters && link_number > 0) {
      linkSlug = createSlug(chapters[(link_number - 1)].chapter_title)
      linkTitle = chapters[(link_number - 1)].chapter_title
      return (
        <div>
          <Link to={`/book/${parent_slug}/${link_number}-${linkSlug}`}>
            <div>{link_text}:</div>
            Chapter {link_number} - {linkTitle}
          </Link>
        </div >
      )
    } else {
      return null;
    }
  }
}

export default ChapterNavLink
