import React from 'react'
import { createSlug } from '../../utilities/helpers'
import { Link } from 'gatsby'

const ChapterList = ({ list, current_chapter, parent_book }, index) => (
  list.map(({ chapter_number, chapter_title, drupal_id, id }) => {
    const transformedTitle = createSlug(chapter_title);

    if (current_chapter === chapter_number) {
      return (
        <li key={id} className={current_chapter === chapter_number ? 'active-chapter' : ''}>Chapter {chapter_number}: {chapter_title}</li>
      )
    } else {
      return (
        <li key={id}>
          <Link key={drupal_id} to={`/${parent_book}/${chapter_number}-${transformedTitle}`} >
            Chapter {chapter_number}: {chapter_title}
          </Link>
        </li>
      )
    }


  })

)

export default ChapterList