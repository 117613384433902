import React from 'react'
import { createSlug } from '../../utilities/helpers'

import ChapterNavLink from './ChapterNavLink'

const PrevNextLinks = ({ books, current_chapter }) => (

  books.map((book) => {
    const { relationships } = book;
    const { chapters } = relationships;

    let totalChapters = chapters.length;
    let nextLinkNumber = current_chapter < totalChapters
      ? current_chapter + 1
      : '';

    let prevLinkNumber = current_chapter <= totalChapters
      ? current_chapter - 1
      : current_chapter === totalChapters ? ''
        : ''

    let parentTitle = createSlug(book.title);
    let parentNid = book.drupal_internal__nid;

    let parentSlug = `${parentTitle}-${parentNid}`

    return (
      <div className="navlinks" key={parentNid}>
        <ChapterNavLink
          link_number={prevLinkNumber}
          link_text={"Previous"}
          chapters={chapters}
          parent_slug={parentSlug}
        />
        <ChapterNavLink
          link_number={nextLinkNumber}
          link_text={"Next"}
          chapters={chapters}
          parent_slug={parentSlug}
        />
      </div>
    )
  })


)

export default PrevNextLinks;
