import React from 'react'
import { Link } from 'gatsby'
import ChapterList from './ChapterList'
import { bookSlug } from '../../utilities/helpers'

import BackArrow from '../icons/BackArrow'

const RelatedChapters = ({ books, current_chapter }) => (

  books.map((book) => {
    const { relationships } = book;
    const { chapters } = relationships;

    const parentSlug = bookSlug(book.title, book.drupal_internal__nid);

    return (
      <ul className="chapter__list" key={book.drupal_internal__nid}>
        <Link className="chapter__book-link" to={`/${parentSlug}`}>
          <BackArrow />
          <span className="title">{book.title}</span>
        </Link>
        <ChapterList
          list={chapters}
          current_chapter={current_chapter}
          key={book.drupal_internal__nid}
          parent_book={parentSlug} />
      </ul>
    )

  })


)

export default RelatedChapters;
