import React from "react";
import { graphql } from 'gatsby';

import Layout from '../components/layout';

import RelatedChapters from '../components/chapters/RelatedChapters'
import PrevNextLinks from '../components/chapters/PrevNextLinks'


const ChapterTemplate = ({ data }) => {

  const { chapters } = data;
  const { chapter_title, chapter_number, body } = chapters;
  const { relationships } = chapters;
  const { christian_book } = relationships;

  return (
    <Layout>
      <section className="title__wrapper chapter">
        <h1>Chapter {chapter_number}: {chapter_title}</h1>
      </section>

      <section className="container">
        <div class="grid">
          <section>
            <div dangerouslySetInnerHTML={{ __html: body.processed }}></div>
          </section>
          <section className="grid__col--sidebar">
            <RelatedChapters books={christian_book} current_chapter={chapter_number} />

          </section>
        </div>
        <PrevNextLinks books={christian_book} current_chapter={chapter_number} />
      </section>
    </Layout >
  )
}

export default ChapterTemplate;

export const query = graphql`
query($chapter_id: Int!) {
  chapters(drupal_internal__id: {eq: $chapter_id}) {
    chapter_number
    chapter_title
    drupal_id
    body {
      processed
    }
    relationships {
      christian_book {
        drupal_internal__nid
        title
        relationships {
          chapters {
            chapter_title
            chapter_number
            drupal_id
            id
          }
        }
      }
    }
  }
}
`
